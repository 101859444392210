/* FONTS */
/* @font-face {
  font-family: 'Studio Pro';
  src: url("../../assets/fonts/StudioPro-SemiBold.woff2") format("woff2"),
       url("../../assets/fonts/StudioPro-SemiBold.woff") format("woff");
} */

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.rs-base {
  z-index: 999999 !important;
  right: 20px !important;
  left: auto !important;
}

html {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 200;  
}

@media only screen and (max-width: 1440px) {
  html {
    font-size: 16px;
  }
}

#root {
  height: 100%;
}

/* Applied to the body element */
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  background-color: black;
}

.container {
  width: 100%;
  height: 100%;
}

a-scene {
  margin-left: 0% !important;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 720px), (pointer: coarse) {
  a-scene {
    margin-left: 0;
    width: 100%;
  }
}

.a-enter-vr {
  /* position: fixed !important; */
  /* z-index: 99999; */
  display: none;
}

.--captures .a-enter-vr {
  display: block;
}

#switch_camera_btn {
  position: fixed;
  z-index: 999999;
  bottom: 1rem;
  right: 1rem;
}
.a-modal {
  visibility: visible;
  pointer-events: auto;
}

.--loading .a-modal {
  visibility: hidden;
  pointer-events: none;
}

.a-modal .a-dialog-allow-button {
  background-color: white;
  color: black;
}

.a-modal .a-dialog-deny-button, .a-modal .a-dialog-ok-button {
   background-color: transparent;
   border: 1px solid white;
   color: white;
}

.a-modal .a-dialog {
  background-color: transparent;
  border-radius: 0; 
  color: white;
  border: 1px solid white;
  height: 150px;
}

.a-modal .a-dialog-text-container {
  -webkit-box-pack: initial;
      -ms-flex-pack: initial;
          justify-content: initial;
  text-align: center;
}
 
.a-modal .a-dialog-deny-button,
.a-modal .a-dialog-ok-button,
.a-modal .a-dialog-allow-button {
   -webkit-box-shadow: none;
           box-shadow: none;
   text-transform: uppercase;
}

#interface {
  z-index: 99999;
  position: relative;

  height: 100%;
  width: 100%;
  
  pointer-events: none;
  /* display: none; */
}

.interface-wrapper {  
  margin: 1.5rem;
  /* height: calc(100% - 3rem);
  width: calc(100% - 3rem); */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  position: fixed;
}

.--has-overlay #interface {
  background-color: rgba(0, 0, 0, .6);
}

@media only screen and (max-width: 720px) {
  .interface-wrapper {
    margin: .75rem;
    /* height: calc(100% - 1.5rem);
    width: calc(100% - 1.5rem); */
  }
}

.--loading a-scene {
  opacity: 0;
}

a-scene {
  -webkit-transition: opacity .25s cubic-bezier(0.215, 0.61, 0.355, 1) .5s;
  -o-transition: opacity .25s cubic-bezier(0.215, 0.61, 0.355, 1) .5s;
  transition: opacity .25s cubic-bezier(0.215, 0.61, 0.355, 1) .5s;
  opacity: 1;
}

.--loading #interface {
  background-color: black;
}

.loading-wrapper {
  opacity: 1;
  -webkit-transition: opacity .25s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity .25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity .25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.--fade-out-loader .loading-wrapper {
  opacity: 0;
}

.--loading #a-canvas {
  pointer-events: none;
}

.--invert-ui #interface {
  -webkit-filter: invert(1.0);
          filter: invert(1.0);
}

.--loading.--invert-ui #interface {
  -webkit-filter: initial;
          filter: initial;  
}

#loader {
  width: 100%;
  max-width: 400px;
  height: auto;

  border-radius: 15px;

  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#bar {
  width: 30%;
  background-color: Gainsboro;

  border-radius: 15px;

  position: absolute;
  top: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
      -ms-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
}

#tutorial_wrapper {
  position: fixed;
  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  pointer-events: auto;
}

#cursor {
  position: fixed;
  z-index: 99999;

  -webkit-transform: translate(-50%, -50%);

      -ms-transform: translate(-50%, -50%);

          transform: translate(-50%, -50%);

  width: 75px;
  height: 75px;

  content: '';
  background-size: contain;

  border: 2.5px solid white;
  border-radius: 100%;

  -webkit-transition: width .25s cubic-bezier(0.215, 0.61, 0.355, 1), height .25s cubic-bezier(0.215, 0.61, 0.355, 1);

  -o-transition: width .25s cubic-bezier(0.215, 0.61, 0.355, 1), height .25s cubic-bezier(0.215, 0.61, 0.355, 1);

  transition: width .25s cubic-bezier(0.215, 0.61, 0.355, 1), height .25s cubic-bezier(0.215, 0.61, 0.355, 1);
  /* display: none; */
}

#cursor .question-cursor {
  display: none;
}

#cursor.show-info-cursor {
  border: none;
  width: 45px;
  height: 45px;
}

#cursor.show-info-cursor .question-cursor {
  display: block;
}

#cursor .close-icon {
  display: none;
}

body.--has-info-overlay #cursor.show-info-cursor .close-icon {
  display: block;
}

body.--has-info-overlay #cursor.show-info-cursor .question-cursor,
body.--has-info-overlay #cursor .switch-countdown {
  display: none;
}


@media only screen and (max-width: 720px), (pointer: coarse) {
  #cursor {
    border: none !important;
    background: none !important;
    
    top: 50% !important;
    left: auto !important;
    right: 3rem;
    
    width: auto;
    height: auto;
    
    -webkit-transform: translateY(-50%);
    
        -ms-transform: translateY(-50%);
    
            transform: translateY(-50%);
  }
  
  #cursor .switch-countdown {
    bottom: 0;
    left: 0;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    
    position: relative;
  }
}

* {
  cursor: none !important;
}

.--hide-cursor {
  cursor: pointer !important;
}

.--hide-cursor #cursor {
  display: none;
}

.--captures *,
.--loading * {
  cursor: auto !important;
}

.aframe-inspector-opened #interface {
  display: none;
}

.aframe-inspector-opened * {
  cursor: auto !important;
}

#cursor.direction-next {
  background-image: url(../../../../static/media/next-cursor.67bafb08.svg);
}

#cursor.direction-prev {
  background-image: url(../../../../static/media/prev-cursor.a873a70a.svg);
}

#cursor.direction-none:not(.show-info-cursor) {
  width: 20px;
  height: 20px;
}

#logo_button {
  position: absolute;
  top: 1rem;
  left: 50%;

  -webkit-transform: translateX(-50%);

      -ms-transform: translateX(-50%);

          transform: translateX(-50%);

  width: 4rem;
  
  pointer-events: auto;
}

/* #logo_button.active #logo {
  height: 45px;
  line-height: 45px;
  margin-top: -10px;
} */

#logo {
  width: 100%;
}

@media only screen and (max-width: 720px), (pointer: coarse) {
  #logo {
    width: 3rem;
    top: .5rem;
  }
}

button:focus {
  outline: none;
}

.corner-button,
#close_about_mobile {
  position: absolute;

  background-color: transparent;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;

  height: 75px;
  white-space : nowrap; /* this does the trick */

  display: inline-block;

  padding: 0;
  margin: 0;

  cursor: pointer;
  pointer-events: auto;
  
  z-index: 10;
}

@media only screen and (max-width: 1440px), (max-height: 1200px) {
  .corner-button,
  #close_about_mobile {
    height: 45px;
  }
}

.corner-button img,
#close_about_mobile img {
  height: 100%;
  width: auto;
  float: left;
}

.corner-button .button-label {
  text-transform: uppercase;
  color: white;

  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.02rem;

  margin-left: .5rem;

  display: inline-block;

  height: 75px;
  line-height: 75px;

  font-family: -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;

  -webkit-transform: translateX(-.5rem);

      -ms-transform: translateX(-.5rem);

          transform: translateX(-.5rem);
  -webkit-transition: all .25s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all .25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all .25s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

@media only screen and (max-width: 1440px), (max-height: 1200px) {
  .corner-button .button-label {
    height: 45px;
    line-height: 45px;
  }
}

@media only screen and (pointer: coarse) {
  .corner-button .button-label {
    display: none;
  }
}

#close_about_mobile {
  right: 0;
  top: 0;
  
  display: none;
}

.--has-info-overlay #close_about_mobile {
  display: block;
}

.corner-button.flipped .button-label {
  -webkit-transform: translateX(.5rem);
      -ms-transform: translateX(.5rem);
          transform: translateX(.5rem);
}

.show-labels .corner-button .button-label {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}


.corner-button.flipped img {
  float: right;
}

.corner-button.flipped .button-label {
  margin-left: 0;
  margin-right: .75rem;
}

.corner-button.position-top-left {
  top: 0;
  left: 0;
}

.corner-button.position-top-right {
  top: 0;
  right: 0;
}

.corner-button.position-bottom-left {
  bottom: 0;
  left: 0;
}

.corner-button.position-bottom-right {
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 720px), (pointer: coarse) {
  .corner-button.position-top-right {
    top: auto;
    bottom: 0;
    left: 0;
    
    display: none;
  }
  
  .corner-button.position-top-left {
    top: auto;
    bottom: 0;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  
  .corner-button.position-bottom-left {
    left: 0;
  }
}

#navigator {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#left_side {
  height: 100%;
  width: 50%;
  left: 0;

  opacity: .5;

  display: inline-block;

  background-color: red;

  cursor: e-resize;
}

#right_side {
  height: 100%;
  width: 50%;
  right: 0;

  opacity: .5;

  display: inline-block;

  background-color: blue
}

/* OVERLAYS */
.--has-overlay .corner-button {
  opacity: .4;
  pointer-events: none;
}

.corner-button.active {
  opacity: 1;
  pointer-events: auto;
}

.overlay {
  visibility: hidden;
}

.visible.overlay {
  visibility: visible;
}

.info-overlay,
.about-overlay,
.a-z-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  /* background-color: rgba(0, 0, 0, .6); */
}

.info-overlay p,
.about-overlay p {
  font-size: 2.25vw;
  color: white;
  
  width: 75%;
  
  margin-bottom: 2.5vw;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.about-overlay p a {
  color: white;
}

.info-overlay p:first-of-type,
.about-overlay p:first-of-type {
  margin-top: 2rem;
}

.a-z-overlay-items-wrapper,
.info-overlay-text-wrapper,
.about-overlay-text-wrapper {
  position: absolute;
  top: 7rem;
  left: 0;
  right: 0;
  
  height: calc(100% - 14rem);
  
  border-bottom: 1px solid white;
    
  overflow-y: scroll;
  overflow-x: hidden;
  pointer-events: auto;
}

.a-z-overlay-items-wrapper {
  border-top: 1px solid white;
}

.a-z-overlay-items-wrapper .a-z-item,
.a-z-overlay-items-wrapper .a-z-header {
  height: 5rem;
  line-height: 5rem;
  
  font-size: 2rem;
  color: white;
  
  border-bottom: 1px solid white;
  
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.a-z-overlay-items-wrapper .a-z-header {
  height: 3rem;
  line-height: 3rem;
  
  font-size: 1.5rem;
}

.a-z-overlay-items-wrapper .a-z-block-wrapper:last-of-type .a-z-item:last-of-type {
  border-bottom: none;
}

.about-overlay .about-inline-icon {
  height: 2rem;
  width: auto;
  
  display: inline-block;
  margin-right: .5rem;
  margin-bottom: -.5rem;
}

.about-overlay p.p-half-space {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1440px), (max-height: 1200px) {
  .a-z-overlay-items-wrapper,
  .about-overlay-text-wrapper,
  .info-overlay-text-wrapper {
    top: 6rem;
    
    height: calc(100% - 12rem);
  }
  
  .a-z-overlay-items-wrapper .a-z-item,
  .a-z-overlay-items-wrapper .a-z-header {
    height: 4rem;
    line-height: 4rem;
    
    font-size: 1.5rem;
  }
  
  .a-z-overlay-items-wrapper .a-z-header {
    height: 2.5rem;
    line-height: 2.5rem;
    
    font-size: 1rem;
  }
}

@media only screen and (max-width: 720px) {
  .a-z-overlay-items-wrapper,
  .about-overlay-text-wrapper,
  .info-overlay-text-wrapper {
    /* top: 4.5rem; */
    top: 0;
    
    height: 100%;
    /* height: calc(100vh - 9rem); */
    
    border-bottom: 1px solid white;
  }
  
  .info-overlay,
  .about-overlay,
  .a-z-overlay {
    top:4.5rem;
    bottom: 4.5rem;
  }
  
  .info-overlay p,
  .about-overlay p {
    font-size: 6vw;  
    margin-bottom: 6vw;
    
    padding-left: 1rem;
    padding-right: 1rem;
    
    width: 100%;
  }
  
  .a-z-overlay-items-wrapper .a-z-item,
  .a-z-overlay-items-wrapper .a-z-header {
    height: 2.5rem;
    line-height: 2.5rem;
    
    font-size: 1rem;
    
    padding-left: 1rem;
  }
  
  .a-z-overlay-items-wrapper .a-z-header {
    height: 1.5rem;
    line-height: 1.5rem;
    
    font-size: .75rem;
  }
}

.message {
  position: fixed;
  z-index: 999999;
  color: white;
  /* top: calc(50% + 2rem); */
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-size: 8vw;
  display: block;
  width: 90%;
  text-align: center;
  margin: 0;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .05s ease-in-out;
  -o-transition: opacity .05s ease-in-out;
  transition: opacity .05s ease-in-out;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1;
}

.abstract {
  position: fixed;
  z-index: 999999;
  color: white;

  bottom: 2rem;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* text-transform: uppercase; */
  
  display: block;
  width: calc(100% - 20rem);
  text-align: center;
  margin: 0;
  
  pointer-events: none;
  
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1;
  font-size: .8rem;
  /* letter-spacing: .03rem; */
}

@media only screen and (max-width: 1040px), (pointer: coarse) {
  .abstract {
    bottom: 6rem;
    width: calc(100% - 8rem);
  }
}

.abstract-inner {
  position: relative;
  
  max-width: 100%;
  
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  
  height: 1rem;
  
  display: inline-block;
  
  font-size: 1rem;
  text-transform: uppercase;
}

.abstract-more-info {
  display: inline-block;
  /* margin-top: -1rem; */
}

.abstract-more-info span {
  /* font-size: .5rem; */
  text-transform: uppercase;
}

.abstract img {
  display: inline-block;
}


@media only screen and (max-width: 720px) {
  .abstract {
    bottom: 4rem;
    width: 90%;
  }
  
  .abstract-inner {
    width: auto;
    max-width: calc(100% - 4rem);
  }
}

.abstract .more-info-icon {
  width: 2rem;

  margin-left: 1rem;
}

#info_trigger {
  position: fixed;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);

  width: calc(100% - 20rem);
  height: 10rem;
  
  pointer-events: all;
}

@media only screen and (max-width: 720px), (pointer: coarse) {
  #info_trigger {
    bottom: 4rem;
    width: 60%;
    height: 4rem;
  }
}

#tutorial_wrapper h1,
.message h1 {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1;
  font-size: 8vw;
}

#tutorial_wrapper .prev,
#tutorial_wrapper .next {
  position: absolute;
  width: 50%;
  -webkit-transition: .15s ease-in-out;
  -o-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: auto;
}

#tutorial_wrapper .prev-next-divider {
  position: fixed;
  left: 50%;
  top: 50%;
  
  -webkit-transform: translate(-50%, -50%);
  
      -ms-transform: translate(-50%, -50%);
  
          transform: translate(-50%, -50%);
  width: 2px;
  height: calc(100vh - 15rem);
  background-color: white;
}

#tutorial_wrapper .prev:hover,
#tutorial_wrapper .next:hover {
  -webkit-transform: translateY(-50%) scale(1.2);
      -ms-transform: translateY(-50%) scale(1.2);
          transform: translateY(-50%) scale(1.2);
}

#tutorial_wrapper .prev {
  left: 0;
}

#tutorial_wrapper .next {
  right: 0;
}

#tutorial_wrapper .prev,
#tutorial_wrapper .next {
  font-size: 4vw;
}

.message.visible {
  opacity: 1;
  visibility: visible;
}

.--has-overlay .message,
.--has-overlay .abstract {
  display: none;
}

.loading-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  
  -webkit-transform: translate(-50%, -50%);
  
      -ms-transform: translate(-50%, -50%);
  
          transform: translate(-50%, -50%);
  
  width: 84vw;
  height: 40.8vw;
}

.cube .front, .cube .back {
  position: absolute;
  
  width: 32%;
  height: 38%;
  
  /* background-color: rgba(255, 0, 0, .4); */
}

.back {
  -webkit-transform: translateZ(-30.1vw) translateY(8.4vw);
          transform: translateZ(-30.1vw) translateY(8.4vw);
}

.back-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.cube {
  position: relative;
  
  width: 76.8vw;
  height: 76.8vw;
  
  -webkit-transform-style: preserve-3d;
  
          transform-style: preserve-3d;
  
  -webkit-transform-origin: center;
  
      -ms-transform-origin: center;
  
          transform-origin: center;
  -webkit-transform: translate(21.8vw, -27vw) rotateX(-31deg) rotateY(-63deg);
          transform: translate(21.8vw, -27vw) rotateX(-31deg) rotateY(-63deg);
}

#loading_frame {
  width: 100%;
  height: 100%;  
}

.loader-logo {
  width: 50%;
  height: auto;
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loading-text {
  color: white;
  
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  font-weight: 500;
  
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%) translateY(-100%);
      -ms-transform: translateX(-50%) translateY(-100%);
          transform: translateX(-50%) translateY(-100%);
  font-size: 0;
}

.loading {
  -webkit-animation: marquee-top 4s linear infinite;
          animation: marquee-top 4s linear infinite;
  /* animation-fill-mode: both; */
  /* animation-direction: alternate; */
}

@-webkit-keyframes marquee-top {
  0% { -webkit-transform: translateX(-50%) translateY(-100%); transform: translateX(-50%) translateY(-100%); }
  100% { -webkit-transform: translateX(-50%) translateY(100%); transform: translateX(-50%) translateY(100%); }
}

@keyframes marquee-top {
  0% { -webkit-transform: translateX(-50%) translateY(-100%); transform: translateX(-50%) translateY(-100%); }
  100% { -webkit-transform: translateX(-50%) translateY(100%); transform: translateX(-50%) translateY(100%); }
}

.loading-text span {
  display: block;
  
  font-size: 3.9vw;
}

.progress-bar-wrapper {
  width: 70%;
  height: 1.2vw;
  
  border: 2px solid white;
  
  position: absolute;
  bottom: 25%;
  left: 50%;
  
  -webkit-transform: translateX(-50%);
  
      -ms-transform: translateX(-50%);
  
          transform: translateX(-50%)
}

.progress-bar-progress {
  height: 100%;
  width: 0;
  
  background-color: white;
  
  -webkit-transition: width 1s ease-in;
  
  -o-transition: width 1s ease-in;
  
  transition: width 1s ease-in;
}

.switch-countdown {
  position: absolute;
  left: 50%;
  bottom: -.25rem;
  -webkit-transform: translate(-50%, 100%);
      -ms-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%);
  
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  font-weight: 400;

  color: white;
}

.mouse-icon,
.tap-icon,
.keyboard-icon {
  width: 15vw;
  height: auto;
  
  max-width: 7.5rem;
}

.navigation-icon-wrapper {
  margin-top: 2rem;   
}

.keyboard-icon,
.mouse-icon {
  display: inline-block;
  vertical-align: middle;
}

.keyboard-icon {
  margin-left: 3rem;
}

#mobile_prev_button,
#mobile_next_button {
  position: fixed;
  top: 50%;
  
  width: 3rem;
  height: 3rem;
  
  background-color: transparent;
  border: none;
  
  /* display: none; */
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.--has-overlay .mobile-prev-next {
  display: none
}

#mobile_prev_button {
  left: 1rem;  
}

#mobile_next_button {
  right: 1rem;
}

#mobile_logo {
  display: none;
}

@media only screen and (max-width: 720px) {
  .mouse-icon,
  .tap-icon {
    margin-top: 1rem; 
    max-width: 3rem;
  }
  
  /* .tutorial-message-camera.message {
    margin-top: 1.5rem;
  } */
  
  #tutorial_wrapper h1,
  .message h1 {
    font-size: 7vw;
  }
  
  #tutorial_wrapper .prev,
  #tutorial_wrapper .next {
    /* margin-top: -1.5rem; */
    font-size: 3.5vw;
  }
  
  #tutorial_wrapper .prev-next-divider {
    height: 60vh;
  }
  
  /* #mobile_prev_button,
  #mobile_next_button {
    display: block;
  } */
  
  #mobile_prev_button {
    left: .5rem;  
  }

  #mobile_next_button {
    right: .5rem;
  }
  
  .message {
    width: calc(100% - 6rem);
    
    font-size: 5vw;
  }
  
  #loading_frame {
    display: none;
  }
  
  .loading-wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    -webkit-transform: none;
    
        -ms-transform: none;
    
            transform: none;
    
    width: 100%;
    height: 100%;
  }
  
  .cube .front,
  .cube .back {
    width: 100%;
    height: 100%;
    
    -webkit-transform: none;
    
        -ms-transform: none;
    
            transform: none;
  }
  
  .cube {
    width: 100%;
    height: 100%;
    
    -webkit-transform: none;
    
        -ms-transform: none;
    
            transform: none;
  }
  
  .loading-text span {
    font-size: 18vw;
  }
  
  .loader-logo {
    /* top: 2rem; */
    /* width: 20%; */
    
    display: none;
  }
  
  .progress-bar-wrapper {
    width: 100%;
    height: 3rem;
    
    bottom: 0;
    
    border: none;
    
    z-index: 999;
  }
  
  .progress-bar-progress {
    overflow: hidden;
    position: relative;
  }
  
  #mobile_logo {
    position: absolute;
    left: 50vw;
    top: .5rem;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    
    height: 2rem;
    
    display: block;
  }
}